import { H } from 'highlight.run';

if (process.env.NODE_ENV === 'production') {
	try {
		H.init('6gl8kze9', {
			environment: 'production',
			version: 'commit:abcdefg12345',
			tracingOrigins: true,
			networkRecording: {
				enabled: true,
				recordHeadersAndBody: true,
				urlBlocklist: []
			}
		});
	} catch (error) {
		console.error(error);
	}
}
