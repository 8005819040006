import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/aml-policy": [3],
		"/auth/otp-email": [4],
		"/auth/telegram": [5],
		"/casino": [6],
		"/casino/[provider]": [7],
		"/casino/[provider]/[game]": [8],
		"/crypto-games": [9],
		"/games/landing": [10],
		"/games/providers/pg": [11],
		"/games/sportsbook": [12],
		"/live-casino": [13],
		"/privacy-policy": [14],
		"/profile": [15],
		"/referrals": [16],
		"/responsible-gaming": [17],
		"/restricted-jurisdictions": [18],
		"/slots": [19],
		"/sportsbook": [20],
		"/terms-and-conditions": [21],
		"/verify": [22]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';